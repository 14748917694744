import React, { useEffect, useState } from 'react'
import JumboScrollbar from '@jumbo/components/JumboScrollbar/JumboScrollbar';
import StyledMenu from 'app/shared/StyledMenu/StyledMenu';
import { ListSubheader } from '@mui/material';
import Items from './Items';

export const MenuList = ({ itemsList, optSelect, fnExecute = undefined }) => {
  const [selectedIndex, setSelectedIndex] = useState(optSelect);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (fnExecute !== undefined) {
      fnExecute(index);
    }
  };

  useEffect(() => {
    if (optSelect === 0) {
      return;
    }
    setSelectedIndex(optSelect);
  }, [optSelect]);

  return (
    <StyledMenu>
      <JumboScrollbar
        autoHeight
        autoHeightMin={458}
        autoHide
        autoHideDuration={200}
        autoHideTimeout={500}
      >
        {
          itemsList.map((item, index) => {
            if (item.type === 'title') {
              return (
                <ListSubheader key={index} sx={{ lineHeight: '24px' }}>{item.name}</ListSubheader>
              )
            } else {
              // if (item.id == 47) console.log(item);
              return (
                <Items
                  key={`${item.id}-${index}`}
                  name={item.name}
                  qty={item.qty}
                  type={item.type}
                  selected={selectedIndex === item.id}
                  handleListItemClick={(event) => handleListItemClick(event, item.id)}
                />
              )
            }
          })
        }
      </JumboScrollbar>
    </StyledMenu>
  )
}